import React from 'react'

import ProductCard from '../components/Client-side/cards/ProductCard';
import BecomeInvestor from '../components/Client-side/BecomeInvestor';
import WorkingWith from '../components/Client-side/WorkingWith';
import  {Banner} from '../components/Client-side/Banner';
import  bg from "../images/minig_equipment/banner.png"
import molybdenum from "../images/landing_page/Our_product/Molybdenum.png"
import bouxite from "../images/landing_page/Our_product/Bauxite.png"
import nikel from "../images/landing_page/Our_product/Nikel.png"
import rowCopper from "../images/landing_page/Our_product/Raw-Copper.png"
import drilling from "../images/minig_equipment/drilling.png"
import { Link } from 'react-router-dom';

export default function MiningEquipment() {
  return (
      <> 
  <Banner title="Mining Incubator" bg={bg}/>

  <section className='my-20'>
      <div className='container'>
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
            <div className='shadow-2xl leading-3 pl-5 py-6'>
                    <ol className='font-semibold text-gray-500'>
                        <li><Link to="">Phosphates</Link></li>
                        <li><Link to="">Aluminum</Link></li>
                        <li><Link to=""> Industrial Minerals</Link></li>
                        <li><Link to="">Precious metals</Link></li>
                        <li><Link to="">Non-ferrous metals</Link></li>
                        <li><Link to="">Iron and ferrous metals</Link></li>
                    </ol>
            </div>
            <div className='md:col-span-2'>
                 <h2>Latest Mining Products</h2>
                <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 my-2 gap-4'>
                <ProductCard  image={molybdenum} details="Volvo SD115B Smooth Compaction Ruller" title="Compaction Ruller"/>
                <ProductCard  image={bouxite} details="Volvo SD115B Smooth Compaction Ruller" title="Compaction Ruller"/>
                <ProductCard image={molybdenum} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                <ProductCard image={nikel} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                <ProductCard image={rowCopper} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                <ProductCard image={rowCopper} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                </div>
            </div>
        </div>
      </div>
  </section>

  <section className='my-20'>
      <div className='container'>
          <div>
              <h1 className='text-3xl font-semibold text-primary pb-2'>Underground Grill</h1>
              <hr/>
          </div>
          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 my-2 gap-4'>
              <ProductCard  image={molybdenum} details="Volvo SD115B Smooth Compaction Ruller" title="Compaction Ruller"/>
              <ProductCard  image={bouxite} details="Volvo SD115B Smooth Compaction Ruller" title="Compaction Ruller"/>
              <ProductCard image={molybdenum} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
            <ProductCard image={nikel} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
            <ProductCard image={rowCopper} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
            <ProductCard image={rowCopper} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
          </div>
      </div>
  </section>
  <hr/>


   
    <section className='my-20'>
      <div className='container'>
          <div>
              <h1 className='text-3xl font-semibold text-primary pb-2'> Related Mining Equipment</h1>
              <hr/>
          </div>
          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 my-2 gap-4'>
          <ProductCard image={molybdenum} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
            <ProductCard image={nikel} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
            <ProductCard image={rowCopper} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
            <ProductCard image={rowCopper} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
          </div>
      </div>
  </section>

    <BecomeInvestor/>
     <WorkingWith/>


  </>
  );
}
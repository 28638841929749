import React from 'react'
import BlogCard from './cards/BlogCard'
import blog from "../../images/Media_blog/image2.png"

export default function BlogSection() {
    return (
        <section className='bg-info my-24 py-20'>
            <div className='container'>
                <div className='w-80 mx-auto pb-12 text-center'>
                     <h1 className='text-4xl text-primary font-semibold '>Media & Blog </h1>
                     <h1 className='border-b-4 border-b-primary mx-auto w-20 pb-3'></h1>
                </div>
                <div className='grid md:grid-cols-7 sm:grid-cols-2 gap-8 '>
                    <div className='md:col-span-2'>
                        <BlogCard title="Ten biggest producing surface mines in Central America in 2020" details="Here are the ten largest surface mining projects by production in South and Central America in 2020, according to  Read More" time="2 hours ago" blogger="Admin"/>
                    </div>
                    <div className='md:col-span-3'><img src={blog} className='w-full'/></div>
                    <div className='md:col-span-2'>
                        <BlogCard title="Ten biggest producing surface mines in Central America in 2020" details="Here are the ten largest surface mining projects by production in South and Central America in 2020, according to  Read More" time="3 hours ago" blogger="Admin"/>
                    </div>
                </div>
                <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 my-8 gap-6'>
                    <BlogCard title="Ten biggest producing surface mines in Central America in 2020" details="Here are the ten largest surface mining projects by production in South and Central America in 2020, according to  Read More" time="2 hours ago" blogger="Admin"/>
                    <BlogCard title="Ten biggest producing surface mines in Central America in 2020" details="Here are the ten largest surface mining projects by production in South and Central America in 2020, according to  Read More" time="2 hours ago" blogger="Admin"/>
                    <BlogCard title="Ten biggest producing surface mines in Central America in 2020" details="Here are the ten largest surface mining projects by production in South and Central America in 2020, according to  Read More" time="2 hours ago" blogger="Admin"/>
                    <BlogCard title="Ten biggest producing surface mines in Central America in 2020" details="Here are the ten largest surface mining projects by production in South and Central America in 2020, according to  Read More" time="2 hours ago" blogger="Admin"/>
                </div>
            </div>
        </section>
    )
}

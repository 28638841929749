import React from 'react'
import {Banner} from '../components/Client-side/Banner'

import EventCard from '../components/Client-side/cards/EventCard'
import BlogSection from '../components/Client-side/BlogSection'
import bg from "../images/Event_page/banner.png"
import internationMining from "../images/Event_page/internation_mining.png"
import event1 from "../images/Event_page/event1.png"
import event2 from "../images/Event_page/event2.png"
import event3 from "../images/Event_page/event3.png"
import event4 from "../images/Event_page/event1.png"


export default function Events() {
    return (
        <>
            <Banner title="Events" bg={bg}/>
            <section className='my-20'>
                <div className=' mx-52'>
                    <div className='py-20 px-16' style={{backgroundImage:`url(${internationMining})`, backgroundPosition:"center", backgroundSize:"cover"}}>
                        <p className='text-yellow-500'>10 January 2022</p>
                        <h1 className='text-white text-2xl'>International Mining and Resources Conference (IMARC)</h1>
                        <p className='text-yellow-500 text-2xl'><i className="fa fa-map-marker" aria-hidden="true"></i><span className='ml-2 text-base'>Convention & Exhibition Centre, Riadah, Saudi Arabia</span></p>
                    </div>
                   
                </div>
            </section>

            <section className='my-20 py-20' style={{backgroundColor:"#F5F5F5"}}>
                <div className='container'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4'>
                        <EventCard img={event1} title="27th International Mining Congress and Exhibition of Turkey" details="Think about it – the finest hotels, restaurants, bars, hospitals, airports and special events all have one thing in common. Read More" date="18 Jan 2021"/>
                        <EventCard img={event2} title="27th International Mining Congress and Exhibition of Turkey" details="Think about it – the finest hotels, restaurants, bars, hospitals, airports and special events all have one thing in common. Read More" date="18 Jan 2021"/>
                        <EventCard img={event3} title="27th International Mining Congress and Exhibition of Turkey" details="Think about it – the finest hotels, restaurants, bars, hospitals, airports and special events all have one thing in common. Read More" date="18 Jan 2021"/>
                        <EventCard img={event4} title="27th International Mining Congress and Exhibition of Turkey" details="Think about it – the finest hotels, restaurants, bars, hospitals, airports and special events all have one thing in common. Read More" date="18 Jan 2021"/>
                    </div>
                </div>
            </section>

           <BlogSection/>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BiEditAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { deleteBlogCategory, fetchBlogCategories } from '../../../api/blog/Category'

export const ListBlogCategory = () => {
    const [ categories, setCategories ] = useState([])
    useEffect(()=>{
  
      // loading blog categories 
       fetchBlogCategories().then((res:any)=>{
           if(res.status===200){
            setCategories(res.data);
           }
       })
    },[])
  
  
    /*  delete category    */
  
    const deleteRequest= async (categoryId:string) =>{
        await deleteBlogCategory(categoryId).then((res:any)=>{
             if(res.status===204)
             {
              fetchBlogCategories().then((res:any)=>{
                if(res.status===200){
               
                  setCategories(res.data);
                }
            })
             }
        })
    }
  
  return(
    <>
    <div className="flex mx-auto flex-col">
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden">
          <table className="min-w-full text-center">
            <thead className="border-b bg-white">
              <tr>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                  #
                </th>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                  category Name
                </th>
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                   Action
                </th>
              
              </tr>
            </thead>
            <tbody>
              {categories.map((category:any,index:number)=>(
                 <tr className="bg-white border-b">
                 <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{index+1}</td>
                 <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                   {category.name}
                 </td>
                  <td className='font-medium'><button><Link  to={`/admin/blog-category/${category.id}`}><BiEditAlt/></Link></button><button className='pl-3' onClick={()=>{deleteRequest(category.id)}}><AiFillDelete/></button></td>
               </tr>
              ))}
            
            </tbody>
          </table>
  
  
        </div>
      </div>
    </div>
    
  </div>
  
         
          </>
  )
}

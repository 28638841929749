import React from 'react'
import { Link } from 'react-router-dom'

 const  ProductCard:React.FC<{image:string, details:string,title:string}> =({image, details,title}) => {
  return (
      <section>
        <div className="product-content hidden sm:block">
          <div className="product-box shadow-md text-center border rounded-md border-gray-200 border-solid m-2">

              <div className='product-image border-b border-white'>
                <img src={image} className='w-full rounded-t-md h-56' alt="product"/>
              </div>
            <hr/>
            <div className="py-5 px-3 text-left w-full ">
                <h1 className='text-lg text-primary mb-0'>{title}</h1>
                <p className="text-gray-700 text-base mb-3">
                    {details}
                </p>
                <Link to="/product-details" className='border border-primary w-full py-1 px-3 text-primary text-lg rounded-md'>View Details</Link>
            </div>
          </div>
        </div>

      <div className="product-content  sm:hidden">
        <div className="product-box mt-2 shadow-md mr-auto ml-0 -mb-2 text-center border rounded-lg border-gray-200 border-solid ">
            <div className='flex  items-center'>
            <div className='shrink-0'>
              <img src={image} className='w-36 h-52 rounded-l-lg ' alt="product"/>
            </div>
            <hr/>
            <div className="grow ml-2">
              <h1 className='text-sm text-primary mb-0'>{title}</h1>
              <p className="text-gray-700 text-sm mb-3">
                  {details}
              </p>
              <Link to="/product-details" className='border border-primary w-full py-1 px-3 text-primary text-sm rounded-md'>View Details</Link>
            </div>
            </div>
            </div>
        </div>
      </section>
  )
}


export default ProductCard
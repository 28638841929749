import React, { useEffect, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BiEditAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { deleteBlogPost, fetchBlogPosts } from '../../../api/blog/Post'
import { BASE_URL } from '../../../Url/BaseUrl'

export const ListBlogPost = () => {
  const [ posts, setPosts] = useState([])

  useEffect(()=>{

    // loading blog categories 
     fetchBlogPosts().then((res:any)=>{
         if(res.status===200){
          setPosts(res.data);
         }
     })
  },[])


  /*  delete category    */

  const deleteRequest= async (categoryId:string) =>{
      await deleteBlogPost(categoryId).then((res:any)=>{
           if(res.status===204)
           {
            fetchBlogPosts().then((res:any)=>{
              if(res.status===200){
                 setPosts(res.data);
              }
          })
           }
      })
  }

return(
  <>
  <div className="flex mx-auto flex-col">
  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full text-center">
          <thead className="border-b bg-white">
            <tr>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                #
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                Photo
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                Post title
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                Post details
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                 Action
              </th>
            
            </tr>
          </thead>
          <tbody>
            {posts.map((post:any,index:number)=>(
               <tr className="bg-white border-b">
               <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{index+1}</td>
               <td><img src={BASE_URL+post.photo} alt="" width="200" height="100"/></td>
               <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                 {post.title}
               </td>
               <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                 {post.details}
               </td>
                <td className='font-medium'><button><Link  to={`/admin/blog-post/${post.id}`}><BiEditAlt/></Link></button><button className='pl-3' onClick={()=>{deleteRequest(post.id)}}><AiFillDelete/></button></td>
             </tr>
            ))}
          
          </tbody>
        </table>


      </div>
    </div>
  </div>
  
</div>
</>
)

}

import React from 'react'
import { Banner } from '../components/Client-side/Banner'

import BecomeInvestor from '../components/Client-side/BecomeInvestor'
import WorkingWith from '../components/Client-side/WorkingWith'
import bg from "../images/About_us/banner.png"

export default function About() {
    return (
        <>
            <Banner  title="About Us" bg={bg}/>
            <section className='my-20'>
                <div className='container'>
                    <div className='grid grid-cols-2 gap-12'>
                        <div>
                            <h1 className='text-3xl text-primary font-semibold mb-6'>Who we are?</h1>
                            <p className='text-justify'>Mining services extract minerals, metals, gemstones, and other material from the earth for industrial and commercial use.  Mining services include may provide several broad service types, including.General mining services include underground and surface drilling, blasting, mineral assaying (geochemical and precious metals analysis), sample preparation, underground mining, exploration drilling, loading and hauling services, handheld mining, and shotcrete/fibrecrete services.</p>
                        </div>
                        <div>
                            <img src="../images/About_us/who-we-are.png" className="w-full"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className='my-20'>
                <div className='container'>
                    <div className='grid grid-cols-2 gap-12'>
                        <div>
                            <img src="../images/About_us/have-any-question-like-this.png" className="w-full"/>
                        </div>
                        <div>
                            <h1 className='text-3xl text-primary font-bold mb-6'>Have any question like this?</h1>
                            <ul className='text-xl'>
                                <li>Are you looking for mining investor?</li>
                                <li>Do you want to be mining investor?</li>
                                <li>Do you want to know how to find investor?</li>
                                <li>Do you have any mining project or company that needs to finance?</li>
                                <li>Are you Involving in financeing of mining?</li>
                            </ul>
                        </div>
                      
                    </div>
                </div>
            </section>

            <BecomeInvestor/>
            <WorkingWith/>
        </>
    )
}

import React from 'react'

export default function BlogCard({title, details, time, blogger}) {
  return (
    <div className="flex justify-center">
      <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
        <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">{title}</h5>
        <p className="text-gray-700 text-base mb-4">
            {details}
        </p>
         <h3 className='text-lg'>Postted by {blogger}|{time}</h3>
      </div>
    </div>
  )
}


import React from 'react'
import {

    Routes,
    Route
  } from "react-router-dom";
import About from '../../pages/About';
import Home from  "../../pages/Home";
import Service from  "../../pages/Service";
import Events from  "../../pages/Events";
import Training from  "../../pages/Training";
import Contact from  "../../pages/Contact";
import Layout from '../layouts/Layout';
import MiningEquipment from '../../pages/MiningEquipment';
import Investment from '../../pages/Investment';
import MediaBlog from '../../pages/MediaBlog';
import FindInvestor from '../../pages/FindInvestor';
import BecomeInvestor from '../../pages/BecomeInvestor';
import ProductDetails from '../../pages/ProductDetails';


export default function PublicRoute() {
    return (
        <Layout>
        <Routes>
            <Route  path="/" element={<Home/>} />
            <Route  path="/about" element={<About/>} />
            <Route  path="/service" element={<Service/>} />
            <Route  path="/events" element={<Events/>} />
            <Route  path="/training" element={<Training/>} />
            <Route  path="/contact" element={<Contact/>} />
            <Route  path="/mining-products" element={<MiningEquipment/>}/>
            <Route  path="/investment"  element={<Investment/>}/>
            <Route  path="/media-blog"  element={<MediaBlog/>}/>
            <Route  path="/find-investor"  element={<FindInvestor/>}/>
            <Route  path="/become-investor"  element={<BecomeInvestor/>}/>
            <Route  path="/product-details"  element={<ProductDetails/>}/>
        </Routes>
        </Layout>
    )
}

import React from 'react'
import { Banner} from '../components/Client-side/Banner'
import bg from "../images/contact_us/banner.png"

export default function Contact() {
    return (
        <>
            <Banner title="Contact Us" bg={bg}/>
            <section className='py-20 bg-secondary text-white'>
                <div className='container'>
                    <div className='grid grid-cols-3 gap-12'>
                    <div className="col-span-2 block p-6  w-full">
                     <form>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="form-group mb-6">
                                <input type="text" className="form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput123"
                                aria-describedby="emailHelp123" placeholder="Full Name"/>
                            </div>
                            <div className="form-group mb-6">
                                <input type="email" className="form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
                                aria-describedby="emailHelp124" placeholder="email"/>
                            </div>

                            <div className="form-group mb-6">
                                <input type="text" className="form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput123"
                                aria-describedby="emailHelp123" placeholder="Contact Number"/>
                            </div>
                            <div className="form-group mb-6">
                                <input type="email" className="form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
                                aria-describedby="emailHelp124" placeholder="What do you want to inquer?"/>
                            </div>

                            <div className="form-group mb-6">
                                <input type="text" className="form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput123"
                                aria-describedby="emailHelp123" placeholder="Service Name"/>
                            </div>
                            <div className="form-group mb-6">
                                <input type="email" className="form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
                                aria-describedby="emailHelp124" placeholder="Address"/>
                            </div>
                        </div>

                        <div className=''>
                            <textarea className='resize-none form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none mb-6' rows={5} placeholder='Type your message'></textarea>
                        </div>
                        
                       
                        
                        <button type="submit" className="
                        w-full
                        px-6
                        py-2.5
                        bg-blue-600
                        text-white
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        shadow-md
                        hover:bg-blue-700 hover:shadow-lg
                        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                        active:bg-blue-800 active:shadow-lg
                        transition
                        duration-150
                        ease-in-out">Sign up</button>
                    </form>
                    </div>
                     <div>
                          <h1 className='text-2xl text-white font-semibold pt-4'>Social Media</h1>
                          <hr/>
                          <div className='flex py-5'>
                             <a href="#" className='ml-5 bg-primary px-4 py-1 rounded-full'>
                                <i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="#" className='ml-3 bg-primary px-3 py-1 rounded-full'>
                                <i className="fab fa-linkedin" aria-hidden="true"></i>
                                </a>
                                <a href="#" className='ml-3 bg-primary px-3 py-1 rounded-full'>
                                <i className="fab fa-twitter"></i>
                             </a>
                          </div>
                          <h1 className='text-2xl text-white font-semibold pt-4'>Address</h1>
                          <hr/>
                          <p><i className="fa fa-map-marker" aria-hidden="true"></i>Riadah Incubators Startup Studio And corporate factory - Khaldiya Towers - 4th Tower - Faisal Bin Turki Road - Office No. 6 - Floor 13 - Riyadh</p>
                          <p>+966 55 117 5959 </p>
                          <p>info@saudihemam.com</p>
                     </div>
                    </div>
                </div>
            </section>

            <section>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58010.66069641818!2d46.6655639!3d24.6696123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03e34eeffc47%3A0xfe09bb19801c399e!2sRiadah%20Incubators!5e0!3m2!1sen!2sbd!4v1630588941006!5m2!1sen!2sbd" className='h-96 w-full border-none'  allowFullScreen={true} loading="lazy"></iframe>
            </section>
        </>
    )
}

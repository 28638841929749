import React from 'react'
import coal from "../images/Our_product _details/cock-or-coal.png"

export default function ProductDetails() {
    return (
        <>
            <div className='bg-gray-100 py-20'>
                <section>
                    <div className='container'>
                        <div className='grid grid-cols-3  gap-4 '>
                            <div className='col-span-2 bg-white'>
                                <div>
                                    <img src={coal} alt="" className="w-full"/>
                                    <h1 className='h-8 bg-secondary text-white px-5'>Cock / Coal</h1>

                                    <div className='p-5'>
                                        <p>As a leading raw materials trading company, thyssenkrupp ensures just-in-time supplies to its customers worldwide. The product range includes coal, coke, special ores, alloys, metals, minerals, and industrial gases, as well as energy services. Outstanding knowledge of products and markets and close ties to leading producers and suppliers ensure security of supply. Our full-service offering includes advice on applications, as well as comprehensive quality management.</p>
                                        <h6>Contact us for Order / Deal</h6>
                                        <hr/>
                                        <label>Name <span className='text-red-500'>*</span></label>
                                        <input type="text" className='py-1 px-3 mb-5 rounded-sm border w-full bg-gray-100' />
                                        <label>Email Address  <span className='text-red-500'>*</span></label>
                                        <input type="email" className='py-1 px-3 rounded-sm border w-full bg-gray-100' />

                                        <label> How much need this item?  <span className='text-red-500'>*</span></label>
                                        <input type="number" className='py-1 px-3 mb-5 rounded-sm border w-full bg-gray-100' />

                                        <label> Company Name <span className='text-red-500'>*</span></label>
                                        <input type="text" className='py-1 px-3 mb-5 rounded-sm border w-full bg-gray-100' />

                                        <label>City <span className='text-red-500'>*</span></label>
                                        <input type="text" className='py-1 px-3 mb-5 rounded-sm border w-full bg-gray-100' />

                                        <label> State <span className='text-red-500'>*</span></label>
                                        <input type="text" className='py-1 px-3 mb-5 rounded-sm border w-full bg-gray-100' />

                                        <label> Zip Code  <span className='text-red-500'>*</span></label>
                                        <input type="text" className='py-1 px-3 mb-5 rounded-sm border w-full bg-gray-100' />
                                        <label> Attachments (if any)  <span className='text-red-500'>*</span></label>
                                        <input type="file" className='py-1 px-3 rounded-sm border w-full bg-gray-100' />

                                        <label>Message</label>
                                        <textarea className='w-full resize-none border bg-gray-100' rows={5}></textarea>
                                        <button className='py-2 px-5 mt-5 rounded-sm bg-secondary text-yellow-500' >Submit</button>
                                    </div>
                                   
                                </div>
                            </div>
                            <div>
                                 <h1></h1>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58010.66069641818!2d46.6655639!3d24.6696123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03e34eeffc47%3A0xfe09bb19801c399e!2sRiadah%20Incubators!5e0!3m2!1sen!2sbd!4v1630588941006!5m2!1sen!2sbd"  className='h-96 w-full border-none'  allowFullScreen={true} loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

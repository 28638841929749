import React from 'react'
import { Banner } from '../components/Client-side/Banner'
import BlogSection from '../components/Client-side/BlogSection'
import BlogCard from '../components/Client-side/cards/BlogCard'
import bg from "../images/Media_blog/banner.png" 
import blog from "../images/Media_blog/image.png"

export default function MediaBlog() {

  return ( <> 
  <Banner bg={bg} title="Meida & Blog"/> 
  < section className = 'my-10' > <div className='container'>
    <nav className="bg-grey-light rounded-md w-full">
      <ol className="list-reset flex">
        <li>
          <a href="#" className="text-primary hover:text-blue-700">Home</a>
        </li>
        <li>
          <span className="text-primary mx-2"></span>
        </li>
        <li>
          <a href="#" className="text-primary hover:text-blue-700">Blog</a>
        </li>
        <li>
          <span className="text-gray-500 mx-2"></span>
        </li>
        <li className="text-gray-500">Ten biggest producing surface mines in Central America in 2020</li>
      </ol>
    </nav>
  </div>
</section>
<hr/>

<section className='mb-20 mt-10'>
  <div className='container'>
    <div className='grid sm:mx-12 md:mx-20 lg:mx-32 '>
        <img src={blog} alt="" className='w-full h-32'/>
        <h1 className='text-2xl my-4'>Contrary to popular belief, Lorem Ipsum is not simply random text.</h1>
        <div className='flex'>
          <img
            src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
            className="rounded-full w-10"
            alt="Avatar"/>
          <div className='flex items-center ml-2'>
            <p>Posted By Abu Taleb \\ 08 January 2022 \\share Post:</p>
            <a href="#" className='ml-5 text-secondary'>
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className='ml-3 text-secondary'>
              <i className="fab fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="#" className='ml-3 text-secondary'>
              <i className="fab fa-twitter"></i>
            </a>
          </div>

        </div>
        <p className='text-justify mt-10'>
          There are many variations of passages of Lorem Ipsum available, but the majority
          have suffered alteration in some form, by injected humour, or randomised words
          which don't look even slightly believable. If you are going to use a passage of
          Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the
          middle of text. All the Lorem Ipsum generators on the Internet tend to repeat
          predefined chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with a handful
          of model sentence structures, to generate Lorem Ipsum which looks reasonable.
          The generated Lorem Ipsum is therefore always free from repetition, injected
          humour, or non-characteristic words etc.
        </p>
        <p className='text-justify'>
          There are many variations of passages of Lorem Ipsum available, but the majority
          have suffered alteration in some form, by injected humour, or randomised words
          which don't look even slightly believable. If you are going to use a passage of
          Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the
          middle of text. All the Lorem Ipsum generators on the Internet tend to repeat
          predefined chunks as necessary, making this the first true generator on the
          Internet. It uses a dictionary of over 200 Latin words, combined with a handful
          of model sentence structures, to generate Lorem Ipsum which looks reasonable.
          The generated Lorem Ipsum is therefore always free from repetition, injected
          humour, or non-characteristic words etc.
        </p>
       
      </div>
  </div>
</section>
<section>
  <BlogSection/>
</section>
</>
  )
    }
import React from 'react'
import { Link } from 'react-router-dom';
import {Banner} from '../components/Client-side/Banner';
import bg from "../images/become_investor/banner.png"
import investor from  "../images/become_investor/Want-to-be-investor.png"

export default function BecomeInvestor() {
    return (
        <>
            <Banner title="Become Investor" bg={bg}/>
            <section className='my-20'>
                <div className='container'>
                     <div className='grid grid-cols-2 gap-12'>
                         <div>
                             <img src={investor} alt=""  className='w-full'/>
                         </div>
                         <div>
                             <h1 className='text-2xl font-semibold mb-5'>Want to be investor?</h1>
                             <p className='mb-5'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English</p>
                             <Link to="/contact" className='bg-secondary text-yellow-600 px-4 py-2'>Apply Now</Link>
                         </div>
                     </div>
                </div>
            </section> 

            <section className='my-20' style={{backgroundImage:`url(${"../images/become_investor/Right-Time,-Right-Place.png"})`, backgroundPosition:'center', backgroundSize:"cover"}}>
                <div className='bg-black opacity-80'>
                    <div className='container'>
                        <div className='py-10 px-10 text-center'>
                            <h1 className='text-yellow-600 text-2xl font-semibold mb-5'>Right Time, Right Place <br/>
Risk free Invest, Safe Revenue</h1>
                            <p className='mb-5 text-white'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section className='my-20'>
                <div className='container'>
                    <h1 className='text-2xl font-semibold pb-2 border-b'>Know Government Rulls</h1>
                    <ol>
                        <li className='flex mt-5'><h1 className='font-semibold text-3xl text-gray-300 mr-5'>01.</h1><p>Mining: The process of extracting useful ores or mineral formations from the Earth or maritime
areas, or evaporites which result from the evaporation of seawater or natural water bodies, including
any direct or indirect mining activity required for such purpose </p>
                        </li>
                        <li className='flex mt-5'><h1 className='font-semibold text-3xl text-gray-300 mr-5'>02.</h1><p>Exploration: An extensive search for deposits conducted on any site using geological, geophysical,
or geochemical methods, or digging, or any other appropriate method to determine the presence of
such deposits, their extent, quantity, quality, and mining viability. </p>
                        </li>
                        <li className='flex mt-5'><h1 className='font-semibold text-3xl text-gray-300 mr-5'>03.</h1><p>Exploitation: The process of extracting ores and minerals, by mining or quarrying, including any
direct or indirect activity required to achieve this purpose </p>
                        </li>
                        <li className='flex mt-5'><h1 className='font-semibold text-3xl text-gray-300 mr-5'>04.</h1><p>Minerals: Inorganic metallic or non-metallic compounds with distinctive chemical compositions
                        and physical properties, classified as follows: <br/>
                            <span className="mb-5">Class A: Metallic minerals, precious and semi-precious stones, and ores that require advanced
                        operations and concentration as classified in the Regulations;</span><br/>
                        Class B: Non-metallic minerals, industrial minerals, and raw materials, as classified in the
                            Regulations; and <br/>
                            Class C: Materials used for construction purposes, as classified in the Regulations
                        </p>
                        </li>
                    </ol>
                </div>
            </section>
        </>
    )
}

import React, { useState } from 'react';

export default function Dashboard() {

    return (
        <div className='grid grid-cols-2'>
            <div>
                <h1>Hello</h1>
            </div>
               <div>
                   <h2>Hello 2</h2>
               </div>
           
        </div>
    )
}

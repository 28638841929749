import React from 'react'

import slide1 from "../../images/slide_1.png"
import slide2 from "../../images/slide_2.png"
import slide3 from "../../images/slide_3.png"


export default function SlideShow() {
    return (
      <div id="carouselExampleCaptions" className="carousel slide relative" data-bs-ride="carousel">
  <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="0"
      className="active hidden"
      aria-current="true"
      aria-label="Slide 1"
    ></button>
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="1"
      aria-label="Slide 2"
      className='hidden'
    ></button>
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="2"
      aria-label="Slide 3"
      className='hidden'
    ></button>
  </div>
  <div className="carousel-inner relative w-full overflow-hidden">
    <div className="carousel-item active relative float-left w-full">
      <img
        src={slide1}
        className="block w-full h-60"
        alt="..."
      />
      <div className="carousel-caption hidden md:block absolute text-center">
        <h5 className="text-2xl font-bold text-yellow-500">MINING INVESTMENT</h5>
        <p className='text-xl font-semibold'>Buy, Sale & Rent Mining Equeipment.</p>
      </div>
    </div>
    <div className="carousel-item  relative float-left w-full">
      <img
        src={slide2}
        className="block w-full h-60"
        alt="..."
      />
      <div className="carousel-caption hidden md:block absolute text-center">
        <h5 className="text-2xl font-bold text-yellow-500">MINING INVESTMENT</h5>
        <p className='text-xl font-semibold'>Buy, Sale & Rent Mining Equeipment.</p>
      </div>
    </div>
    <div className="carousel-item  relative float-left w-full">
      <img
        src={slide3}
        className="block w-full h-60"
        alt="..."
      />
      <div className="carousel-caption hidden md:block absolute text-center">
        <h5 className="text-2xl font-bold text-yellow-500">MINING INVESTMENT</h5>
        <p className='text-xl font-semibold'>Buy, Sale & Rent Mining Equeipment.</p>
      </div>
    </div>
  </div>
  <button
    className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    )
}

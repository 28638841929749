import React from 'react'
import {Banner} from '../components/Client-side/Banner'

import { Link } from 'react-router-dom'
import WorkingWith from '../components/Client-side/WorkingWith'
import BecomeInvestor from '../components/Client-side/BecomeInvestor'
import  bg  from "../images/investment/banner.png"
import investment from "../images/investment/mining-investment.png"
import question from "../images/investment/have-any-question-like-this.png"
import net1 from "../images/investment/mining_net1.png"
import net2 from "../images/investment/mining_net2.png"

export default function Investment() {
    return (
        <>
            <Banner title="Investment" bg={bg}/>
            <section className='my-20'>
                <div className='container'>
                    <div className='grid grid-cols-2 gap-8'>
                        <div>
                            <h1 className='text-3xl mb-5 font-semibold'>Mining Investment</h1>
                            <p  className='text-justify'>Mining Investment Asia is becoming one of the must-attend events on my calendar as it brings together a broad spectrum of delegates and speakers from the industry. The conference also allows for great networking opportunities and gaining an understanding of the mining industry from different perspectives.</p>
                            <h3 className='mt-5'>
                                    <span className='text-2xl '>Clyde Russell</span><br/>
                                    Commodities & Energy Columnist
                            </h3>
                        </div>
                        <div>
                            <img src={investment} alt="" className='w-full'/>
                        </div>
                    </div>
                   
                </div>
            </section>
            <section className='my-20'>
                <div className='container'>
                    <div className='grid grid-cols-2 gap-12'>
                        <div>
                            <img src={question} alt="" className='w-full'/>
                        </div>
                        <div>
                            <h1 className='text-2xl uppercase font-semibold mb-5 '>Have any question like this?</h1>
                            <ul>
                                <li>Are you looking for mining investor?</li>
                                <li>Do you want to be mining investor?</li>
                                <li>Do you want to know how to find investor?</li>
                                <li>Do you have any mining project or company that needs to finance?</li>
                                <li>Are you Involving in financeing of mining?</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <BecomeInvestor/>
            <WorkingWith/>

            <section className='my-20'>
                <div className='container'>
                    <div className='grid grid-cols-3 gap-4'>
                        <div>
                            <h1 className='text-2xl font-semibold mb-2'>Mining Network</h1>
                            <p className='text-justify mb-5'>We know you want to maximise business contacts but avoid long drawn superficial small talk. We know you want to know someone better to build business relationships but different people thrive in different social settings.
                            At Mining Investment Conferences, we provide varied networking platforms for you to make meaningful connections for long term partnership and collaborations. </p>

                            <Link to="contact-us" className='bg-secondary py-2 px-5 text-white'>Contact Us</Link>
                        </div>
                        <div className='col-span-2 p-10 flex justify-between bg-secondary'>
                            <img src={net1} alt="" className='w-full border-8 border-white'/>
                            <img src={net2} alt="" className="w-full border-8 border-white ml-5"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

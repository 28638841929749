import React from 'react'
import work1 from "../../images/minig_equipment/work1.png"
import work2 from "../../images/minig_equipment/work2.png"
import work3 from "../../images/minig_equipment/work3.png"
import work4 from "../../images/minig_equipment/work4.png"
import work5 from "../../images/minig_equipment/work5.png"

export default function WorkingWith() {
    return (
        <section className='bg-info mt-24 py-20'>
        <div className='container'>
            <div className='w-80 mx-auto pb-12 text-center'>
                <h1 className='text-4xl text-primary font-semibold '> <span className='text-2xl'>We are </span><br/> Working With </h1>
                <h1 className='border-b-4 border-b-primary mx-auto w-20 pb-3'></h1>
            </div>
            <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-4'>
              <img src={work1} alt="" className='w-full'/>
              <img src={work2} alt="" className='w-full'/>
              <img src={work3} alt="" className='w-full'/>
              <img src={work4} alt="" className='w-full'/>
              <img src={work5} alt="" className='w-full'/>
            </div>
        </div>
    </section>
    )
}

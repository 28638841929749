import React from 'react'

export default function ServiceCard({image,details}) {
  return (
    <div className="flex justify-center">
      <div className="rounded-lg shadow-lg bg-info py-4">
         <div className='flex justify-center'>
            <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
            <img src={image}   alt=""/>
            </a>
         </div>
        <div className="p-6">
          <p className="text-gray-700 text-base mb-4">
            {details}
          </p>

        </div>
      </div>
    </div>
  )
}

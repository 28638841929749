import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import AdminRoute from "./components/Route/AdminRoute";

import PublicRoute from "./components/Route/PublicRoute";
import 'tw-elements';
import './tailwind.css';
import { useEffect } from "react";

function App(){

  return (

    <>
      <BrowserRouter>
          <Routes>
            
             <Route path="/admin/*" element={<AdminRoute/>} />
             <Route  path="/*" element={<PublicRoute/>}/>
          </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;

import React from 'react'

export  const  Banner:React.FC<{bg:string,title:string}> = ({ bg, title }) => {
  return (
    <section
      className='title-banner'
      style={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundPosition: "center",
    
    }}>
      <div className="mx-auto py-20">
        <h1 className='text-3xl text-center text-white font-semibold'>{title}</h1>
        <h1 className='border-b-4 border-b-yellow-500 mx-auto w-20 pb-3'></h1>
      </div>
    </section>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function EventCard({ img, title, details, date }) {
    return (
        <div className="flex justify-center">
        <div className="rounded-lg shadow-lg bg-white max-w-sm">
          <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
            <img src={img} className='w-full'  alt=""/>
          </a>
          <div className="pt-2 pb-5 px-3">
            <p className='text-gray-400'>{date}</p>
            <h5 className="text-secondary text-xl font-semibold mb-2">{title}</h5>
            <p className="text-gray-700 text-justify text-base mb-4">
              {details}
            </p>
            <Link to="" className='px-5 py-2 bg-secondary text-white'>Add To Calendar</Link>
          </div>
        </div>
      </div>
    )
}

import React, { useState} from 'react'
import AdminSidebar from '../partials/AdminSidebar';
import Header from '../partials/Header/Header';

export default function AdminLayout(props) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <>
         <div className="flex">
         <AdminSidebar/>
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-gray-300">

        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        
       
            {props.children}
            </div>
            
            </div>
       </>
       
    )
}

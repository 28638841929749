import React from 'react'
import { Link } from 'react-router-dom';
import { Banner} from '../components/Client-side/Banner'
import bg from "../images/investment/banner.png"
import training1 from "../images/training/training1.png"
import training2 from "../images/training/training2.png"

export default function Training() {
    return (
        <>
            <Banner title="Training" bg={bg}/>
            <section className='text-white bg-gray-100 py-20'>
                <div className='container'>
                    <div>
                        <h1>How To Apply to take the trainning</h1>
                    </div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-8'>
                         <div className='bg-secondary rounded-full h-60 w-60 justify-center flex items-center'>
                             <div className=''>
                                <a className='text-4xl pl-14' href=""><i className="fas fa-sign-in-alt"></i></a>
                                <p className='uppercase text-2xl'>Sign Up Here</p>
                             </div>
                         </div>
                         <div className='bg-secondary rounded-full h-60 w-60 justify-center flex items-center'>
                             <div className=''>
                                <a className='text-4xl pl-20' href=""><i className="fas fa-copy"></i></a>
                                <p className='uppercase text-2xl'>Fill up the form </p>
                             </div>
                         </div>

                         <div className='bg-secondary rounded-full h-60 w-60 justify-center flex items-center'>
                             <div className=''>
                                <a className='text-4xl pl-20' href=""><i className="fas fa-envelope-square"></i></a>
                                <p className='uppercase text-2xl'>Get Confirmation</p>
                             </div>
                         </div>

                         <div className='bg-secondary rounded-full h-60 w-60 justify-center flex items-center'>
                             <div className=''>
                                <a className='text-4xl pl-20' href=""><i className="fas fa-edit"></i></a>
                                <p className='uppercase text-2xl'>Start Training</p>
                             </div>
                         </div>
                    </div>
                </div>
            </section>

            <section className='my-20'>
                <div className='container'>
                    <div className='grid sm:grid-cols-2 gap-12'>
                        <div>
                            <img src={training1} className='w-full'/>
                        </div>
                        <div>
                               <h1>Free-to-play Mining training</h1>
                               <p className='text-justify mb-5'>This guide describes the most effective free-to-play methods to train the Mining skill. Mining is a gathering skill which typically involves collecting ores (and occasionally geodes) from different types of rocks, often found in groups in mines. A pickaxe is required when mining. Levelling up the Mining skill increases the chance of the player getting critical hits, which deal greater amounts of damage to rocks and so increase the rate of receiving ores and the larger experience drop that occurs alongside this. A higher Mining level gives chances of receiving double ores from certain types of rock; and increases the frequency of rockertunities, which also grant extra damage when used. As players level up they are also able to use better pickaxes. To gain the best experience rates possible players are strongly recommended to use the highest level pickaxe available to them and use rockertunities whenever available.<br/>
                               
                               The experience rate estimates in this guide assume that the best possible pickaxe is being used for the stated Mining level and do not incorporate the use of any experience boosting items or bonus experience. They also assume that players do not bank the ores that they obtain, as this would reduce xp/hr, although whether it is worth it to bank the ore would depend on the market, proximity, and preferences.<br/>
                               Players using the Evolution of Combat mode can drop ores by adding the ore to their action bar and pressing the associated keybind to drop the ore. As opposed to dropping items directly from the inventory using their right-click drop option, dropping ores via the action bar does not interrupt mining. Players who do wish to keep the ores that they mine could make use of the ore box, and expansions to its capacity to decrease the frequency of trips to the bank.
                               
                               </p>

                               <Link to="/contact" className='px-5 py-2 bg-primary text-yellow-500 rounded-sm'>Contact Us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className='my-20'>
                <div className='container'>
                    <div className='grid sm:grid-cols-2 gap-12'>
                        <div>
                            <img src={training2} className='w-full'/>
                        </div>
                        <div>
                               <h1>Free-to-play Mining training</h1>
                               <p className='text-justify mb-5'>This guide describes the most effective free-to-play methods to train the Mining skill. Mining is a gathering skill which typically involves collecting ores (and occasionally geodes) from different types of rocks, often found in groups in mines. A pickaxe is required when mining. Levelling up the Mining skill increases the chance of the player getting critical hits, which deal greater amounts of damage to rocks and so increase the rate of receiving ores and the larger experience drop that occurs alongside this. A higher Mining level gives chances of receiving double ores from certain types of rock; and increases the frequency of rockertunities, which also grant extra damage when used. As players level up they are also able to use better pickaxes. To gain the best experience rates possible players are strongly recommended to use the highest level pickaxe available to them and use rockertunities whenever available.<br/>
                               
                               The experience rate estimates in this guide assume that the best possible pickaxe is being used for the stated Mining level and do not incorporate the use of any experience boosting items or bonus experience. They also assume that players do not bank the ores that they obtain, as this would reduce xp/hr, although whether it is worth it to bank the ore would depend on the market, proximity, and preferences.<br/>
                               Players using the Evolution of Combat mode can drop ores by adding the ore to their action bar and pressing the associated keybind to drop the ore. As opposed to dropping items directly from the inventory using their right-click drop option, dropping ores via the action bar does not interrupt mining. Players who do wish to keep the ores that they mine could make use of the ore box, and expansions to its capacity to decrease the frequency of trips to the bank.
                               
                               </p>

                               <Link to="/contact" className='px-5 py-2 bg-primary text-yellow-500 rounded-sm'>Contact Us</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

import React from 'react'
import {
    Routes,
    Route
  } from "react-router-dom";
  
import Dashboard from '../../pages/Admin/Dashboard'
import AdminLayout from '../layouts/AdminLayout'
import  "../../css/admin/style.scss";
import { CreateBrand } from '../../pages/Admin/Brand/CreateBrand';
import { BrandList } from '../../pages/Admin/Brand/BrandList';
import { EditBrand } from '../../pages/Admin/Brand/EditBrand';
import { ListCategory } from '../../pages/Admin/Category/ListCategory';
import { CreateCategory } from '../../pages/Admin/Category/CreateCategory';
import { EditCategory } from '../../pages/Admin/Category/EditCategory';
import { ListProductType } from '../../pages/Admin/ProductType/ListProductType';
import { CreateProductType } from '../../pages/Admin/ProductType/CreateProductType';
import { EditProductType } from '../../pages/Admin/ProductType/EditProductType';
import { CreateProduct } from '../../pages/Admin/Product/CreateProduct';
import { ListBlogCategory } from '../../pages/Admin/blog-category/ListBlogCategory';
import { CreateBlogCategory } from '../../pages/Admin/blog-category/CreateBlogCategory';
import { EditBlogCategory } from '../../pages/Admin/blog-category/EditBlogCategory';
import { ListBlogPost } from '../../pages/Admin/blog-post/ListBlogPost';
import { CreateBlogPost } from '../../pages/Admin/blog-post/CreateBlogPost';
import { EditBlogPost } from '../../pages/Admin/blog-post/EditBlogPost';
import Contact from '../../pages/Contact';
import { ListContact } from '../../pages/Admin/contact/ListContact';
import { ListEvent } from '../../pages/Admin/event/ListEvent';
import { CreateEvent } from '../../pages/Admin/event/CreateEvent';
import { EditEvent } from '../../pages/Admin/event/EditEvent';
export default function AdminRoute() {
    return (
         <AdminLayout>
             <Routes>
                    <Route  path="/" element={<Dashboard/>}/>
                    {/* product related route  */}
                    <Route path="/brand" element={<BrandList/>}/>
                    <Route path="/brand/create" element={<CreateBrand/>}/>
                    <Route path="/brand/:brandId" element={<EditBrand/>}/>
                    <Route path="/category" element={<ListCategory/>}/>
                    <Route path="/category/create" element={<CreateCategory/>}/>
                    <Route path="/category/:categoryId" element={<EditCategory/>}/>
                    <Route path="/product-type" element={<ListProductType/>}/>
                    <Route path="/product-type/create" element={<CreateProductType/>}/>
                    <Route path="/product-type/:productTypeId" element={<EditProductType/>}/>
                    <Route path="/product/create" element={<CreateProduct/>}/>
                      {/* blog related route  */}
                    <Route path="/blog-category" element={<ListBlogCategory/>}/>
                    <Route path="/blog-category/create" element={<CreateBlogCategory/>}/>
                    <Route path="/blog-category/:categoryId" element={<EditBlogCategory/>}/>
                    <Route path="/blog-post" element={<ListBlogPost/>}/>
                    <Route path="/blog-post/create" element={<CreateBlogPost/>}/>
                    <Route path="/blog-post/:postId" element={<EditBlogPost/>}/>
                    <Route path="/contact" element={<ListContact/>}/>
                       {/* event related route  */}
                    <Route path="/event" element={<ListEvent/>}/>
                    <Route path="/event/create" element={<CreateEvent/>}/>
                    <Route path="/event/:eventId" element={<EditEvent/>}/>
             </Routes>
         </AdminLayout>
    )
}

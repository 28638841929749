import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchBlogPost, updateBlogPost } from '../../../api/blog/Post'
import { fetchCategories } from '../../../api/Product/Category'

export const EditBlogPost = () => {
    const [post, setPost] = useState<any>({})
    const [cats, setCats] = useState([])
    const [message, setMessage ] = useState("")

    let { postId } = useParams() 
  
   useEffect(()=>{

    /* get all posts */
     fetchBlogPost(postId).then((res)=>{
              if(res.status===200)
              { 
                 setPost(res.data);
              }
          })

       /* get all categories */
        fetchCategories().then((res)=>{
            if(res.status===200)
            {
                  setCats(res.data);
            }
        })
   },[])
  
    /* update brand form */
    const handleUpdatePost = async (e:any) => {
        e.preventDefault();
      const data = new FormData(e.target);
       await updateBlogPost(postId,data).then(
        (res)=>{
            setMessage(res.data.message)
        }
    ).catch(
        (error)=>{
          if (error.response) {
               
              console.log(error.response);
            
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
        }
    );
  
    }
  
    return (
    <section className='flex justify-center  mt-10'>
    <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
     
    <form onSubmit={handleUpdatePost}>
    <div className="!bg-green-100 rounded-lg py-5 px-6 text-base text-green-700 mb-3" role="alert">
     {message}
  </div>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Name</label>
        <input type="text" className="input" name="title" value={post.title} onChange={(e:any)=>setPost((post:any)=>({
            ...post,
            title:e.target.value
        }))
        }
         />
      
      </div>

      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Name</label>
        <textarea className="input" rows={3} name="details" value={post.details} onChange={(e:any)=>setPost((post:any)=>({
            ...post,
            details:e.target.value
        })) }
         />
      
      </div>
      <div className="form-group mb-6">
      <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Photo</label>
        <input type="file" className="input" name="photo" />
      </div>

      <div className="form-group mb-6">
      <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Photo</label>
          <select name="cat_id" className='input' value={post.cat_id}>
               {cats.map((cat:any)=>(
                   <option value={cat.id}>{cat.name}</option>
               ))}
          </select>
      </div>
    
  
      <button type="submit" className="
        px-6
        py-2.5
        bg-blue-600
        text-white
        font-medium
        text-xs
        leading-tight
        uppercase
        rounded
        shadow-md
        hover:bg-blue-700 hover:shadow-lg
        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg
        transition
        duration-150
        ease-in-out">Submit</button>
    </form>
  </div>
  </section>
    )
}

import React, {  useState } from 'react'
import { createEvent } from '../../../api/event'

export const CreateEvent = () => {
    const [events, setEvents] = useState([])
    const [message, setMessage ] = useState("")


    
     // create event  after submitting form  
    const handleCreateEvent= async (e:any) => {
        e.preventDefault();
        const data = new FormData(e.target);
  
       await createEvent(data).then(
        (res)=>{
            setMessage(res.data.message)
        }
    ).catch(
        (error)=>{
          if (error.response) {
               
              console.log(error.response);
            
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
        }
    );
  
    }
  
    return (
    <section className='flex justify-center  mt-10'>
    <div className="block p-6 rounded-lg shadow-lg bg-white w-full">
     
    <form onSubmit={handleCreateEvent}>
    <div className="!bg-green-100 rounded-lg py-5 px-6 text-base text-green-700 mb-3" role="alert">
     {message}
  </div>
    <div className='grid grid-cols-2 gap-4'>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Title(en)</label>
        <input type="text" className="input" name="en_title"  />
      
      </div>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Title(ar)</label>
      <input type="text" className="input" name="ar_title" 
           />
      
      </div>
      </div>
    <div className='grid grid-cols-2 gap-4'>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Details(en)</label>
         <textarea className='input' name="en_details" rows={3} ></textarea>
      </div>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Details(ar)</label>
        <textarea className='input' name="ar_details" rows={3} ></textarea>
      
      </div>
    </div>

    <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Photo</label>
         <input type="file" name="photo" className="input"/>
      </div>
    
      <button type="submit" className="
        px-6
        py-2.5
        bg-blue-600
        text-white
        font-medium
        text-xs
        leading-tight
        uppercase
        rounded
        shadow-md
        hover:bg-blue-700 hover:shadow-lg
        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg
        transition
        duration-150 my-2
        ease-in-out">Submit</button>
    </form>
  </div>
  </section>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function BecomeInvestor() {
    return (
        <section className='my-20' style={{backgroundImage:`url(${"../../images/landing_page/Join-with-us.png"})`, backgroundPosition:'center', backgroundSize:'cover'}}>
        <div className='z-10 bg-black opacity-80'>
            <div className='container text-primaryLight text-center py-20'>
                <h1 className='text-4xl font-semibold py-4 opacity-100'>Join with us</h1>
                <p className='text-3xl'>We are growing, has a great culture and is constantly progressing</p>
                <div className='flex justify-center gap-4 py-10'>
                <Link to="/become-investor" type="button" className="inline-block px-6 py-2.5 bg-yellow-500 text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-yellow-600 hover:shadow-lg focus:bg-yellow-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-700 active:shadow-lg transition duration-150 ease-in-out">Become Investor</Link>
                <Link to="/find-investor" className="inline-block px-6 py-2 border-2 border-yellow-500 text-white font-medium text-sm leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Find Investor</Link>
                </div>
            </div>
        </div>
    </section>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
import {Banner} from '../components/Client-side/Banner'
import BecomeInvestor from '../components/Client-side/BecomeInvestor'
import WorkingWith from '../components/Client-side/WorkingWith'
import bg from "../images/Our_service/banner.png"
import underground_drill from "../images/Our_service/ClearPath-Utility-Solutions-for-undground-drilling.png"

export default function Service() {
    return (
        <>
            <Banner title="Our Services" bg={bg}/>
            <section className='my-20'>
                <div className='container'>
                     <div className='grid sm:grid-cols-2 gap-12'>
                        
                         <div>
                             <h1 className='text-2xl font-semibold mb-5'>ClearPath Utility Solutions for undground drilling</h1>
                             <p className='mb-5 text-justify'>When it comes to underground utilities, ClearPath leads the way. We offer a wide range of trenchless repair and replacement services for a variety of utilities, including telecommunications, water, sewer, power, and gas. Our top-quality work and proven results have earned us a great reputation in the community.

We believe the strength of our business comes from our commitment to placing your needs above our own. This means completing each job to the highest quality standards and never compromising on our core values of safety, integrity, and teamwork. “Go Trenchless” by contacting our team for a free estimate of your directional drilling needs. We look forward to the opportunity of serving you.</p>
                            
                         </div>
                         <div>
                             <img src={underground_drill} alt=""  className='w-full'/>
                         </div>
                     </div>
                </div>
            </section> 

            <section className='my-20'>
                <div className='container'>
                    <div>
                        <h1 className='text-2xl font-semibold'>Why choose us?</h1>
                        <hr/>
                        <ul>
                            <li>Exclusive operated underground utilities company</li>
                            <li>Free estimates on your directional drilling project</li>
                            <li>Commitment to safety, integrity and teamwork on every job</li>
                            <li>Well trainup employer are doing this job</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className='my-20'>
                 <div className='container'>
                     <h1 className='font-semibold text-2xl'>What Is Directional Drilling <br/>Used For?</h1>
                     <hr/>
                     <ol>
                         <li>Not long ago, the only way to install new piping underground — or even make simple repairs — was to tear apart the ground and ruin the landscape in a time-consuming trench digging process.</li>

                        <li>With the advent of horizontal directional drilling (also known as directional boring, depending upon the scale of the job), the entire process has been revolutionized. No longer do you have to deal with large crews digging up the ground for weeks or even months to get a single job done.</li>

                        <li>The most common uses of directional drilling is to install pipes, conduits, or cables in areas where traditional open cut construction is difficult to achieve.</li>

                        <li>Directional drilling follows a pre-planned path, creating a minimal amount of impact because it uses a relatively shallow arc.</li>

                        <li>Almost the entirety of the drilling process is done underground, with the exception of the entry and exit sites of the drill bit. Even then, the amount of surface space needed to accomplish directional drilling is minimal.</li>

                        <li>Directional drilling can be used with many different types of pipe materials, including PVC, ductile iron, steel, polypropylene, and polyethylene. As long as the pipes you are using have the thickness and material strength to withstand the stress, they can be utilized.</li>

                        <li>Directional drilling is just another way ClearPath is showing its commitment to leaving as little surface damage as possible while offering a full range of underground utility services.</li>
                     </ol>
                 </div>
            </section>
            <section className='my-20'>
                <div className='container'>
                    <h1 className='text-3xl'>Benefit of give us project or buying our best services</h1>
                    <p className='mb-3'>As a minimal impact process, directional drilling offers a plethora of benefits over traditional trench-heavy methods of installing underground utilities. Here are some of the top benefits of directional drilling:</p>
                    <h1>Minimal Disruption Of Surface</h1>
                    <p className='mb-3'>Unlike traditional underground utility installation which requires a massive amount of trench digging — sometimes over long stretches — directional drilling allows you to avoid this and leave the surface relatively undisturbed.
This means you can do drilling in busy urban areas where shutting down sections of road or sidewalk to dig trenches would cause major disruptions. Or if the work is being done on your home or commercial property, you won’t have to rope off large sections at a time for safety concerns. This is especially important if your business relies heavily upon foot traffic since construction may cause potential customers to avoid your store entirely.</p>
                    <h1>Underground Pipe And Line Installation Is Done Much Quicker</h1>
                    <p>Directional drilling can also be completed at a much faster pace than traditional methods because large sections don’t need to be blocked off so the ground can be dug up. On top of the issues mentioned above, digging trenches makes up a significant portion of the job and can take up a lot of time. Laying down thousands of feet of pipe takes far less time when a long trench does not have to be dug up, especially if some of the areas are located beneath concrete.</p>
                    <h1>Cost Savings</h1>
                    <p>Because it takes less time, directional drilling also requires less manpower than traditional pipe and cable installation methods. When you need a smaller crew to get the job done, you’ll have to pay less for the labor. We are able to complete trenchless jobs far quicker than other companies that rely on traditional methods. Some of our trenchless sewer repair jobs can be completed in less than a day. On top of that, directional drilling makes the best use of modern technology, ensuring less room for human error and malfunctions.
You’ll also avoid the costs associated with hiring a landscaper to fix all the areas that would be damaged with trench digging. All this means more money in your pocket to spend on your home or business.</p>
                    <h1>Lower Environmental Impact</h1>
                    <p className='mb-10'>Any time you don't have to dig trenches and disturb the environment, you’re going to have major benefits. This means you don’t have to replace any landscaping that could be damaged or uprooted in the process. This is especially true if you have permanent hardscaping features or large trees that are difficult to uproot and replant. That alone could save you hundreds or even thousands of dollars.
Digging in cities also poses a great risker of bringing contaminants to the surface. While most soil is safe — though you should always get it tested to be safe — some common contaminants found in cities are radon, petroleum products, pesticides, asbestos, lead, and more. When these contaminated soils are exposed, they can be carried by the wind and inhaled, potentially causing physical or chemical damage.</p>
                    <Link to="" className='py-2 px-4 bg-primary text-yellow-500'>Request to Quote</Link>
                </div>
            </section>

            <BecomeInvestor/>
            <WorkingWith/>

        </>
    )
}

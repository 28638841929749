import React, { useState } from 'react'
import { createBlogCategory } from '../../../api/blog/Category'


export const CreateBlogCategory = () => {
    const [en_name, setEnName] = useState("")
    const [ar_name, setArName] = useState("")
    const [message, setMessage ] = useState("")
  
     // create category after submitting form  
     
    const handleCreateCategory= async (e:React.SyntheticEvent) => {
        e.preventDefault();
        const data ={
          en_name,
          ar_name
        }
  
       await createBlogCategory(data).then(
        (res)=>{
            setMessage(res.data.message)
        }
    ).catch(
        (error)=>{
          if (error.response) {
               
              console.log(error.response);
            
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
        }
    );
  
    }
  
    return (
    <section className='flex justify-center  mt-10'>
    <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
     
    <form onSubmit={handleCreateCategory}>
    <div className="!bg-green-100 rounded-lg py-5 px-6 text-base text-green-700 mb-3" role="alert">
     {message}
  </div>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Name(en)</label>
        <input type="text" className="input" onChange={(e:React.FormEvent<HTMLInputElement>)=>setEnName(e.currentTarget.value)
        }
         />
      
      </div>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Name(ar)</label>
      <input type="text" className="input"  onChange={(e:React.FormEvent<HTMLInputElement>)=>setArName(e.currentTarget.value)
        }
           />
      
      </div>
    
  
      <button type="submit" className="
        px-6
        py-2.5
        bg-blue-600
        text-white
        font-medium
        text-xs
        leading-tight
        uppercase
        rounded
        shadow-md
        hover:bg-blue-700 hover:shadow-lg
        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg
        transition
        duration-150
        ease-in-out">Submit</button>
    </form>
  </div>
  </section>
    )
}

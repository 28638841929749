import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { deleteBrand, fetchBrands } from '../../../api/Product/Brand';

export const BrandList = () => {
  const [ brands, setBrands ] = useState([])
  const [name, setName] = useState("")
  useEffect(()=>{
     fetchBrands().then((res)=>{
         if(res.status===200){
         
            setBrands(res.data.data);
         }
     })
  },[])

  /*  delete brand    */

  const deleteRequest= async (brandId:string) =>{
      await deleteBrand(brandId).then((res)=>{
           if(res.status===204)
           {
            fetchBrands().then((res)=>{
              if(res.status===200){
              
                 setBrands(res.data.data);
              }
          })
           }
      })
  }

return(
  <>
  <div className="flex mx-auto flex-col">
  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full text-center">
          <thead className="border-b bg-white">
            <tr>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                #
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                Brand Name
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">
                 Action
              </th>
            
            </tr>
          </thead>
          <tbody>
            {brands.map((brand:any,index:number)=>(
               <tr className="bg-white border-b">
               <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{index+1}</td>
               <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                 {brand.name}
               </td>
                <td className='font-medium'><button><Link  to={`/admin/brand/${brand.id}`}><BiEditAlt/></Link></button><button className='pl-3' onClick={()=>{deleteRequest(brand.id)}}><AiFillDelete/></button></td>
             </tr>
            ))}
          
          </tbody>
        </table>


      </div>
    </div>
  </div>
  
</div>

       
        </>
)

}

import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import trainingEvent from "../images/landing_page/Training-&-Events.png" 
import prodcut1 from "../images/landing_page/Our_product/Bauxite.png"
import product2 from "../images/landing_page/Our_product/Cockor-Coal.png"
import product3 from "../images/landing_page/Our_product/Diamond.png"
import product4 from "../images/landing_page/Our_product/Mineral-Gold.png"
import product5 from "../images/landing_page/Our_product/Molybdenum.png"
import product6 from "../images/landing_page/Our_product/Nikel.png"
import product7 from "../images/landing_page/Our_product/Raw-Copper.png"
import product8 from "../images/landing_page/Our_product/Uranium.png"

import service1 from "../images/landing_page/service/service_1.png"
import service2 from "../images/landing_page/service/service_2.png"
import service3 from "../images/landing_page/service/service_3.png"
import service4 from "../images/landing_page/service/service_4.png"
import service5 from "../images/landing_page/service/service_5.png"
import service6 from "../images/landing_page/service/service_6.png"
import service7 from "../images/landing_page/service/service_7.png"
import service8 from "../images/landing_page/service/service_8.png"

import whoAre from "../images/landing_page/who-we-are.png"



import ServiceCard from '../components/Client-side/cards/ServiceCard'

import BecomeInvestor from '../components/Client-side/BecomeInvestor'
import BlogSection from '../components/Client-side/BlogSection'
import SlideShow from '../components/Client-side/SlideShow'
import  ProductCard  from '../components/Client-side/cards/ProductCard';

export default function Home() {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      };


  return (
       <> 
       <section>
           <SlideShow/>
       </section>
       
       <section className='bg-white mb-20 py-20 '>
            <div className='container'>
                <div className='text-center mb-5'>
                     <h1 className='text-4xl text-primary font-semibold '> Our Product </h1>
                     <p  className='text-xl pt-3 text-primary'>Mining & Metal</p>
                </div>
            
                 <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                    <ProductCard image={prodcut1} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                    <ProductCard image={product2} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                    <ProductCard image={product3} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                    <ProductCard image={product4} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                    <ProductCard image={product5} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                    <ProductCard image={product6} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                    <ProductCard image={product7} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                    <ProductCard image={product8} title="Raw Copper" details="Volvo SD115B Smooth Compaction Ruller"/>
                  </div>
                </div>
        </section>
        <BecomeInvestor/>
        <section className='service-area my-20'>
            <div className='container'>
                <div className='w-72 mx-auto pb-12 text-center'>
                    <h1 className='text-4xl text-primary font-semibold '>Our Service </h1>
                    <h1 className='border-b-4 border-b-primary mx-auto w-20 pb-3'></h1>
                </div>
                <div className='grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-5 mt-5'>
                     <ServiceCard image={service1} details="Underground and Surface Drilling"/>
                     <ServiceCard image={service2} details="Underground and Surface Drilling"/>
                     <ServiceCard image={service3} details="Underground and Surface Drilling"/>
                     <ServiceCard image={service4} details="Underground and Surface Drilling"/>
                     <ServiceCard image={service5} details="Underground and Surface Drilling"/>
                     <ServiceCard image={service6} details="Underground and Surface Drilling"/>
                     <ServiceCard image={service7} details="Underground and Surface Drilling"/>
                     <ServiceCard image={service8} details="Underground and Surface Drilling"/>
                </div>
            </div>
        </section>
   </>
  )
}
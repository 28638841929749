import React, { useEffect, useState } from 'react'
import { createBlogPost } from '../../../api/blog/Post'
import { fetchCategories } from '../../../api/Product/Category'

export const CreateBlogPost = () => {
    const [en_title, setEnTitle] = useState("")
    const [ar_title, setArTitle] = useState("")
    const [en_details, setEnDetails] = useState("")
    const [ar_details, setArDetails] = useState("")
    const [cat_id, setCatId] = useState();
    const [categories, setCategories] = useState([])
    const [message, setMessage ] = useState("")
  
     useEffect(()=>{
         getCategories();
     },[])

     // get categories from api
     const getCategories = async() =>{
        await fetchCategories().then((res)=>{
           if(res.status===200)
           {
             setCategories(res.data);
           }
        })
     }

     // create blog post after submitting form  
    const handleCreateCategory= async (e:any) => {
        e.preventDefault();
        const data = new FormData(e.target);
  
       await createBlogPost(data).then(
        (res)=>{
            setMessage(res.data.message)
        }
    ).catch(
        (error)=>{
          if (error.response) {
               
              console.log(error.response);
            
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
        }
    );
  
    }
  
    return (
    <section className='flex justify-center  mt-10'>
    <div className="block p-6 rounded-lg shadow-lg bg-white w-full">
     
    <form onSubmit={handleCreateCategory}>
    <div className="!bg-green-100 rounded-lg py-5 px-6 text-base text-green-700 mb-3" role="alert">
     {message}
  </div>
    <div className='grid grid-cols-2 gap-4'>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Name(en)</label>
        <input type="text" className="input" name="en_title" onChange={(e:React.FormEvent<HTMLInputElement>)=>setEnTitle(e.currentTarget.value)
        }
         />
      
      </div>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Name(ar)</label>
      <input type="text" className="input" name="ar_title" onChange={(e:React.FormEvent<HTMLInputElement>)=>setArTitle(e.currentTarget.value)}
           />
      
      </div>
      </div>
    <div className='grid grid-cols-2 gap-4'>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Details(en)</label>
         <textarea className='input' name="en_details" rows={3} onChange={(e:any)=>setEnDetails(e.target.value)}></textarea>
      </div>
      <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Details(ar)</label>
        <textarea className='input' name="ar_details" rows={3} onChange={(e:any)=>setArDetails(e.target.value)}></textarea>
      
      </div>
    </div>
    <div className='grid grid-cols-2 gap-4'>
    <div className="form-group mb-6">
        <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Photo</label>
         <input type="file" name="photo" className="input"/>
      </div>
      <div className="form-group mb-6">
      <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Select Category</label>
         <select className='input' required name="cat_id" onChange={(e:any) => setCatId(e.target.value)}>
          <option value="">Select Category</option>
             {categories.map((category:any,index:number)=>(
                <option value={category.id}>{category.name}</option>
             ))}
              
         </select>
       </div>
    </div>
      <button type="submit" className="
        px-6
        py-2.5
        bg-blue-600
        text-white
        font-medium
        text-xs
        leading-tight
        uppercase
        rounded
        shadow-md
        hover:bg-blue-700 hover:shadow-lg
        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg
        transition
        duration-150 my-2
        ease-in-out">Submit</button>
    </form>
  </div>
  </section>
    )
}

import React, { useEffect, useState } from 'react'
import { getEnabledCategories } from 'trace_events';
import { createProduct } from '../../../api/Product';
import { fetchBrands } from '../../../api/Product/Brand';
import { fetchCategories } from '../../../api/Product/Category';
import { fetchProductTypes } from '../../../api/Product/product-type';

export const CreateProduct = () => {
  /*     this is for product information       */
    const [en_name, setEnName ] = useState("");
    const [ar_name, setArName ] = useState("");
    const [en_details, setEnDetails] = useState("");
    const [ar_details, setArDetails ] = useState("");
    const [cat_id, setCatId] = useState("");
    const [brand_id,  setBrandId] = useState("");
    const [product_type_id,  setProductTypeId] = useState("");
    const [price,  setPrice] = useState("");
    const [photo,  setPhoto] = useState("");
    /* end product information */
    const  [ categories, setCategories] = useState([]);
    const [brands , setBrands ] = useState([]);
    const [types, setTypes] = useState([]);
    const [messge , setMessage] = useState("")

    useEffect(()=>{
      getCategories();
      getBrands();
      getProductTypes();

    },[])


    /* get categories through api*/
    const getCategories = async () =>{
       await fetchCategories().then((res)=>{
           if(res.status===200)
           {
              setCategories(res.data);
           }
       })
    }

      /* get brands  through api*/
      const getBrands = async () =>{
        await fetchBrands().then((res)=>{
            if(res.status===200)
            {
               setBrands(res.data.data);
            }
        })
     }

      /* get product types  through api*/

      const getProductTypes = async () =>{

        await fetchProductTypes().then((res)=>{
            if(res.status===200)
            {
               setTypes(res.data);
            }
        })
     }


     /* save product information throught api */

     const handleProductCreate = async(e:any) => {
        e.preventDefault();

       const  fd = new FormData(e.target);

        await createProduct(fd).then((res)=>{
          console.log(res);
           if(res.status ===201)
           {
               setMessage(res.data.message);
           }
        }).catch(
          (error)=>{
          if (error.response) {
               
              console.log(error.response);
            
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
        })
     }

  return (
    <div className='container'>
       <h2 className='text-green-700 text-center'>Product Information </h2>
       <p className='text-center'>{messge}</p>
    <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md">
      
    <form onSubmit={handleProductCreate}>
      <div className="grid grid-cols-2 gap-4">
        <div className="form-group mb-6">
          <input type="text" className="input" required name="en_name"  placeholder="Product name(en)" onChange={(e:React.SyntheticEvent<HTMLInputElement>)=>setEnName(e.currentTarget.value)} />
        </div>
        <div className="form-group mb-6">
          <input type="text" className="input" required name="ar_name"  placeholder="Product name(ar)" onChange={(e:React.SyntheticEvent<HTMLInputElement>)=>setArName(e.currentTarget.value)} />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="form-group mb-6">
            <textarea className='input' name="en_details" rows={3} required placeholder="Product details(en)" onChange={(e:any)=>setEnDetails(e.currentTarget.value)} ></textarea>
        </div>
        <div className="form-group mb-6">
            <textarea className='input' name="ar_details" rows={3} required onChange={(e:any)=>setArDetails(e.currentTarget.value)}  placeholder="Product details(ar)"></textarea>
        </div>
      </div>
    
      <div className="grid grid-cols-3 gap-4">
      <div className="form-group mb-6">
          <select name="cat_id" required className='input'  onChange={(e:any)=>setCatId(e.currentTarget.value)}>
            <option value="">Select Category </option>
            {categories.map((cat:any,index)=>(
                <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group mb-6">
          <select name="product_type_id" required className='input' onChange={(e:any)=>setProductTypeId(e.currentTarget.value)}>
            <option value="">Select Product Type</option>
            {types.map((type:any,index)=>(
                <option key={type.id} value={type.id}>{type.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group mb-6">
          <select name="brand_id" required className='input' onChange={(e:any)=>setBrandId(e.currentTarget.value)}>
            <option value="">Select Brand </option>
            {brands.map((brand:any,index)=>(
                <option key={brand.id} value={brand.id}>{brand.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="form-group mb-6">
            <input type="file" name="photo" onChange={(e:React.SyntheticEvent<HTMLInputElement>)=>setPhoto(e.currentTarget.value)} className='input' />
        </div>
        <div className="form-group mb-6">
            <input type="text" name="price" className='input' placeholder='Price' onChange={(e:React.SyntheticEvent<HTMLInputElement>)=>setPrice(e.currentTarget.value)}/>
        </div>
      </div>
   
      <button type="submit" className="
        w-full
        px-6
        py-2.5
        bg-blue-600
        text-white
        font-medium
        text-xs
        leading-tight
        uppercase
        rounded
        shadow-md
        hover:bg-blue-700 hover:shadow-lg
        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg
        transition
        duration-150
        ease-in-out">Sign up</button>
    </form>
  </div>
  </div>
  )
}

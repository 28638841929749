import React from 'react'
import logo from "../../images/logo.png"
import { Link } from 'react-router-dom';

export default function Layout(props:any) {
    
 function toggleMobileMenu()
 {
   const menu = document.querySelector(".mobile-menu");
    menu?.classList.toggle("hidden");
 }
    return (
        <>
        <section className='bg-black'>
            <div className='container flex justify-end items-center text-white gap-2 py-2'>
                <p>Follow Us:</p>
                <a  href=""><i className="fab fa-facebook"></i></a>
                <a  href=""><i className="fab fa-twitter"></i></a>
                <a  href=""><i className="fab fa-linkedin"></i></a>|
   

            </div>
        </section>
        <nav className="bg-primary  text-lg">
        <div className="">
            <div className="container">
                <div className="flex items-center justify-between">
                    <div className='flex '>
                    
                        <a href="#" className="flex  py-4 px-2">
                            <img src={logo} alt="Logo" className="h-12 w-16 mr-2"/>
                        
                        </a>
                    </div>
            
                    <div className="hidden lg:flex   pace-x-1 text-white">
                        <ul className='md:flex items-center'>
                            <li  className="py-4 px-3  hover:border-b-2 border-yellow-500"><Link className='y' to="/">Home</Link></li>

                            <li  className="py-4 px-3  hover:border-b-2 border-yellow-500"><Link className='y' to="/mining-products">Mining Products</Link></li>
                         
                            <li  className="py-4 px-3  hover:border-b-2 border-yellow-500"><Link className='y' to="/service">Service</Link></li>
                            
                            <li  className="py-4 px-3  hover:border-b-2 border-yellow-500"><Link className='y' to="/investment">Invest</Link></li>
                            
                            <li  className="py-4 px-3  hover:border-b-2 border-yellow-500 "><Link className='y' to="/events">Events</Link></li>
                            
                            <li  className="py-4 px-3  hover:border-b-2 border-yellow-500 "><Link className='y' to="/training">Training</Link></li>
                            
                            <li  className="py-4 px-3  hover:border-b-2 border-yellow-500 "><Link className='y' to="/media-blog">Media</Link></li>
                           

                            <li  className="py-4 px-3  hover:border-b-2 border-yellow-500 "><Link className='y' to="/contact">Contact Us</Link></li>

                        </ul>
                     
                    </div>
                </div>
        
               

                <div className="lg:hidden flex items-center">
                    <button className="outline-none mobile-menu-button" onClick={toggleMobileMenu}>
                    <svg className=" w-6 h-6 text-gray-500 hover:text-green-500 "
                        x-show="!showMenu"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
                </div>
            </div>
        </div>


        <div className="hidden mobile-menu">
            <ul className='md:flex items-center text-white'>
                <li  className="py-4 px-3  font-semibold"><Link to="/">Home</Link></li>
                <li  className="py-4 px-3  font-semibold"><Link to="/mining-products">Mining Products</Link></li>
               
                <li  className="py-4 px-3  hover:text-yellow-500 transition duration-300"> <Link to="/Service">Service</Link></li>
                <li  className="py-4 px-3  font-semibold hover:text-yellow-500 transition duration-300"> <Link to="/investment">Invest</Link></li>

                <li  className="py-4 px-3   hover:text-yellow-500 transition duration-300"> <Link to="/events">Events</Link></li>
                <li  className="py-4 px-3  hover:text-yellow-500 transition duration-300"> <Link to="/training">Training</Link></li>
                <li  className="py-4 px-3  hover:text-yellow-500 transition duration-300"> <Link to="/media-blog">Media</Link></li>
             
                <li  className="py-4 px-3   hover:text-yellow-500 transition duration-300"> <Link to="/contact">Contact Us</Link></li>
            </ul>
        </div>
    
    </nav>
    {props.children}



    <section className='bg-primary footer text-white py-5'>
        <div className='container'>
            <div className='grid lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 gap-4'>
                    <div className=' col-span-2'>
                        <img src={logo} />
                         <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised</p>
                         <p>Subscribe Here</p>
                    </div>

                    <div>
                          <h1 className=' text-white uppercase border-b'>Useful Link</h1>
                          <ul>
                              <li><a href="/">Home</a> </li> 
                      
                              <li><a href="/investment">Investment</a></li>
                              <li><a href="/media-blog">Media Center</a></li>
                              <li><a href="">Business Policy</a></li>
                              <li><a href="/about">About Us</a></li>
                              <li><a href="/contact">Contact Us</a></li>
                          </ul>
                    </div>

                    <div>
                        <h1 className='text-2xl text-white border-b  '>Quick Link</h1>
                        <ul>
                            <li>Mining Equipment</li>
                            <li>Event</li>
                            <li>Become Investor</li>
                            <li>Find Investor</li>
                            <li>Blog</li>
                            <li>Account</li>
                            <li>Help & Support</li>
                        </ul>
                    </div>

                    <div className='col-span-2'>
                         <h1 className=' text-white border-b'>Get in touch</h1>
                         
                          <p>Mining Incubator Khaldiya Towers - 4th Tower - Faisal Bin Turki Road - Office No. 6 - Floor 13 - Riyadh, Saudi Arabia</p>
                          <p>+966 55 117 5959 </p>
                          <p>info@miningincu.com</p>
                    </div>
            </div>
        </div>
    </section>
    
 

    <section className='bg-primary border-b-2 border-primary'>
        <div className='container text-center py-5 text-white border-t'>
            <p>@copyright-2021. All right reserved media incubator</p>
        </div>
    </section>
    </>
    )
}

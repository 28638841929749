import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchProductType, updateProductType } from '../../../api/Product/product-type'

export const EditProductType = () => {

  const [name, setName] = useState("")
  const [message, setMessage ] = useState("")
  let {productTypeId} = useParams() 

 useEffect(()=>{
        fetchProductType(productTypeId).then((res)=>{
            if(res.status===200)
            {
              console.log(res);
                setName(res.data.name);
            }
        })
 },[])

  /* update product type   */
  const handleProductType = async (e:React.SyntheticEvent) => {
      e.preventDefault();
      const data ={
        name,
      }

     await updateProductType(productTypeId,data).then(
      (res)=>{
          setMessage(res.data.message)
      }
  ).catch(
      (error)=>{
        if (error.response) {
             
            console.log(error.response);
          
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
      }
  );

  }

  return (
  <section className='flex justify-center  mt-10'>
  <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
   
  <form onSubmit={handleProductType}>
  <div className="!bg-green-100 rounded-lg py-5 px-6 text-base text-green-700 mb-3" role="alert">
   {message}
</div>
    <div className="form-group mb-6">
      <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Name</label>
      <input type="text" className="input" value={name} onChange={(e:React.FormEvent<HTMLInputElement>)=>setName(e.currentTarget.value)
      }
       />
    
    </div>
  
  

    <button type="submit" className="
      px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out">Submit</button>
  </form>
</div>
</section>
  )
}
